import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import AboutImg from "../../../components/Image/AboutImg"
import {
  Title,
  SubTitle,
  Button,
  Select,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "ASCII Codes Converter", link: "/ascii-code-converter/" },
]
const seeAlsoArray = [
  "/ascii-and-binary-converter",
  "/binary-decoder",
  "/binary-encoder",
  "/morse-code-conversion",
  "/url-encoder-and-decoder",
]

function ASCIICodesConverter(props) {
  let [textAsciiOption, settextAsciiOption] = useState("1")
  let [asciiInput, setasciiInput] = useState("")
  let [resultCode, setresultCode] = useState("")

  function textAsciiOptionC(e) {
    settextAsciiOption(e.target.value)
    setresultCode("")
  }
  function asciiInputC(e) {
    setasciiInput(e.target.value)
  }
  function convert(e) {
    e.preventDefault()

    let result = []

    // Text to ASCII
    if (textAsciiOption === "1") {
      for (let i = 0; i < asciiInput.split("").length; i++) {
        result[i] = asciiInput.split("")[i].charCodeAt(0)
      }

      setresultCode(result.join(" "))
    } else {
      let chars = asciiInput.split(" ")

      for (let i = 0; i < chars.length; i++) {
        result[i] = String.fromCharCode(chars[i])
      }

      setresultCode(result.join(""))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="ASCII to Text, Text To ASCII Conversion"
        description="Text To ASCII, ASCII to Text Conversion, Simple ASCII Codes code translator, and text to ASCII conversion step by step."
        keywords={[
          "Text To ASCII Codes,ASCII decoder,ASCII encoder,ASCII Decoder Encoder, ASCII Codes code translator, ASCII Codes code, ASCII translator,encoder ASCII,decoder ASCII,ASCII converter.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>ASCII Codes Converter</Title>
        <SubTitle>ASCII codes to Text and Text to ASCII Decimal</SubTitle>
        <Select maxWidth="200px">
          <select
            aria-label="tTA"
            value={textAsciiOption}
            onChange={textAsciiOptionC}
            onBlur={textAsciiOptionC}
          >
            <option value="1">Text To ASCII</option>
            <option value="0">ASCII To Text</option>
          </select>
        </Select>
        <br />
        <Span>Input Here To Convert :</Span>
        <Textarea
          className="mb-3"
          type="text"
          value={asciiInput}
          onChange={asciiInputC}
        ></Textarea>
        <Button
          className="mb-3"
          id="atC"
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          onClick={convert}
        >
          Convert
        </Button>
        <Textarea id="tTaC" value={resultCode} readOnly></Textarea>

        <br />
        <FlexDiv maxWidth="600px">
          <AboutImg filename="ascii.png" alt="ASCII Translator converter" />
        </FlexDiv>
        <br />

        <h3>ASCII</h3>
        <p>
          ASCII used for Information Interchange is a character encoding
          standard for electronic communication. <br /> Most modern
          character-encoding schemes are based on ASCII.
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ASCIICodesConverter
